<template>
  <DefaultLayout>
    <router-view></router-view>
  </DefaultLayout>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'index.vue',
  components: {
    DefaultLayout: () => import('@/layouts/Default.vue'),
  },
});
</script>

<style scoped>

</style>
